import React from 'react';
import dynamic from 'next/dynamic';
import {
  LogoFacebook,
  LogoTwitter,
  LogoYoutube,
  LogoLinkedin,
  LogoInstagram,
  Location,
} from '@carbon/icons-react';
import { NextImage } from '../elements';
import {
  ImagesContentWrapper,
  CopyrightMessageWrapper,
  FooterWrapper,
  FootContentWrapper,
  ColumnTitle,
  ColumnContent,
  FooterSectionWrapper,
  Space,
  FooterContentLine,
  RRSSWrapper,
  RRSSIconWrapper,
  OfficesLinksWrapper,
  OfficeWrapper,
} from './styles';
import { renderLineBreaks } from '../../utils';

import EditorJSContent from '../editor-js-content';

const Stripe = dynamic(() => import('../elements/stripe/stripe'));
const Link = dynamic(() => import('../elements/link/link'));
const LinkGroup = dynamic(() => import('../elements/link-group/link-group'));
const FooterCardGrid = dynamic(() =>
  import('../elements/footer-card-grid/footer-card-grid')
);

const RRSSIcon = ({ link, Icon }) => {
  return (
    link && (
      <Link {...link}>
        <RRSSIconWrapper>
          <Icon size={20} fill="white" />
        </RRSSIconWrapper>
      </Link>
    )
  );
};

const Contact = ({
  title,
  content,
  facebookLink,
  twitterLink,
  youtubeLink,
  linkedinLink,
  instagramLink,
  officesLinks,
}) => {
  const renderRRSS =
    facebookLink || twitterLink || youtubeLink || linkedinLink || instagramLink;
  const hasofficesLinks = officesLinks.length > 0;

  return (
    <FooterSectionWrapper>
      {title && <ColumnTitle>{title}</ColumnTitle>}
      <ColumnContent>
        {content && <EditorJSContent content={content} />}
        {renderRRSS && (
          <RRSSWrapper>
            <RRSSIcon link={facebookLink} Icon={LogoFacebook} />
            <RRSSIcon link={twitterLink} Icon={LogoTwitter} />
            <RRSSIcon link={youtubeLink} Icon={LogoYoutube} />
            <RRSSIcon link={linkedinLink} Icon={LogoLinkedin} />
            <RRSSIcon link={instagramLink} Icon={LogoInstagram} />
          </RRSSWrapper>
        )}
        {hasofficesLinks && (
          <OfficesLinksWrapper>
            {officesLinks.map((office, id) => (
              <OfficeWrapper key={`office-footer-id-${id}`}>
                <Location size={32} />
                <Link {...office} />
              </OfficeWrapper>
            ))}
          </OfficesLinksWrapper>
        )}
      </ColumnContent>
    </FooterSectionWrapper>
  );
};

const Collaborators = ({ title, collaborators }) => {
  return (
    <FooterContentLine>
      <ColumnTitle>{title}</ColumnTitle>
      <ImagesContentWrapper>
        {collaborators &&
          collaborators.map((collaborator) => (
            <NextImage key={collaborator.id} media={collaborator} className="object-contain	" />
          ))}
      </ImagesContentWrapper>
    </FooterContentLine>
  );
};

const CopyRight = ({ links = [] }) => {
  return (
    <CopyrightMessageWrapper>
      {links.map((link) => (
        <span key={link.id}>
          <Link {...link}>{link.text}</Link>
        </span>
      ))}
    </CopyrightMessageWrapper>
  );
};

const Agency = ({ title, content, copyrightLinks }) => {
  return (
    <FooterSectionWrapper>
      {title && <ColumnTitle>{title}</ColumnTitle>}
      {content && (
        <ColumnContent whGap>{renderLineBreaks(content)}</ColumnContent>
      )}
      <Space></Space>
      <CopyRight links={copyrightLinks} />
    </FooterSectionWrapper>
  );
};

const Certificates = ({ title, certificates }) => {
  return (
    <FooterContentLine>
      {title && <ColumnTitle>{title}</ColumnTitle>}
      <ImagesContentWrapper>
        {certificates &&
          certificates.map((certificate) => (
            <NextImage key={certificate.id} media={certificate} />
          ))}
      </ImagesContentWrapper>
    </FooterContentLine>
  );
};

export const Footer = ({
  stripe,
  footerCardGrid,
  contactTitle,
  contactContent,
  facebookLink,
  twitterLink,
  youtubeLink,
  linkedinLink,
  instagramLink,
  officesLinks,
  linkGroup,
  certificatesTitle,
  certificates,
  agencyTitle,
  agencyContent,
  copyrightLinks,
  collaboratorsTitle,
  collaborators,
}) => {
  return (
    <FooterWrapper>
      <Stripe {...stripe} />
      <FooterCardGrid {...footerCardGrid} />
      {certificatesTitle && (
        <Certificates title={certificatesTitle} certificates={certificates} />
      )}
      {collaboratorsTitle && (
        <Collaborators
          title={collaboratorsTitle}
          collaborators={collaborators}
        />
      )}
      <FootContentWrapper>
        {contactTitle && (
          <Contact
            title={contactTitle}
            content={contactContent}
            facebookLink={facebookLink}
            twitterLink={twitterLink}
            youtubeLink={youtubeLink}
            linkedinLink={linkedinLink}
            instagramLink={instagramLink}
            officesLinks={officesLinks}
          />
        )}
        {linkGroup && <LinkGroup {...linkGroup} />}
        {agencyTitle && (
          <Agency
            title={agencyTitle}
            content={agencyContent}
            copyrightLinks={copyrightLinks}
          />
        )}
      </FootContentWrapper>
    </FooterWrapper>
  );
};

export const EmptyFooter = ({
  certificatesTitle,
  certificates,
  agencyTitle,
  agencyContent,
  copyrightLinks,
  collaboratorsTitle,
  collaborators,
}) => {
  return (
    <FooterWrapper>
      {certificatesTitle && (
        <Certificates title={certificatesTitle} certificates={certificates} />
      )}
      {collaboratorsTitle && (
        <Collaborators
          title={collaboratorsTitle}
          collaborators={collaborators}
        />
      )}
      <FootContentWrapper>
        {agencyTitle && (
          <Agency
            title={agencyTitle}
            content={agencyContent}
            copyrightLinks={copyrightLinks}
          />
        )}
      </FootContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
